
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import loaderImage from "../assets/images/loader1.gif";
import profile from "../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'; // Import the entire module
import 'react-toastify/dist/ReactToastify.css';
import './Chat.css'
const ChatMessage = ({ children, avatar, name, timestamp }) => (

  <ChatMessageWrapper>
    <ChatMessageHeader>
      <ChatMessageAvatar>
        <img src={profile} alt={`${name}'s avatar`} />
      </ChatMessageAvatar>
      <ChatMessageName>{name}</ChatMessageName>
      <ChatMessageTimestamp>{timestamp}</ChatMessageTimestamp>
    </ChatMessageHeader>
    <ChatMessageContent>{children}</ChatMessageContent>
  </ChatMessageWrapper>
);
const ChatResponse = ({ children, timestamp }) => (
  <ChatResponseWrapper>
    <ChatResponseHeader>
      <ChatResponseAvatar>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b05ee639c2afc02564f1a50d237625cde245f9b55fced0be428cc6cca618eeca?apiKey=a938513dc279413eaac5ce0f8c637f66&" alt="RAG's avatar" />
        <ChatResponseName>RAG</ChatResponseName>
      </ChatResponseAvatar>
      <ChatResponseTimestamp>{timestamp}</ChatResponseTimestamp>
    </ChatResponseHeader>
    <ChatResponseContent dangerouslySetInnerHTML={{ __html: children }} />
    <ChatResponseIndicator />
  </ChatResponseWrapper>
);

export default function Chat() {
  const [inputValue, setInputValue] = useState("");
  const [responseText, setResponseText] = useState("");
  const [username, setUsername] = useState("");
  const [username2, setUsername2] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copiedData, setCopiedData] = useState('');
  const [thumbsDownValue, setThumbsDownValue] = useState(0);
  const [thumbsUpValue, setThumbsUpValue] = useState(0);
  const [commentValue, setCommentValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [positiveSuggestions, setPositiveSuggestions] = useState(true);
  // const [chatHistory, setChatHistory] = useState([]); 
  const [chatHistory, setChatHistory] = useState(() => {
    const storedChatHistory = sessionStorage.getItem("chatHistory");
    return storedChatHistory ? JSON.parse(storedChatHistory) : [];
  });
  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    setLoading(true);

    const username = sessionStorage.getItem("username");
    const message = inputValue;
    const url = `${apiUrl}/api/v1/chat/submit/${username}/streaming/retrieve_img?request=${encodeURIComponent(message)}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {

          return response.json();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        setLoading(false);
        const formattedData = formatResponseText(data);

        setResponseText(formattedData);
        setChatHistory([
          ...chatHistory,
          { question: message, answer: data, timestamp: new Date().toLocaleString() },
        ]);
        setInputValue("");
        setMessageSent(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      const extractedUsername = storedUsername.split("_")[0];
      setUsername2(extractedUsername);
      setUsername(storedUsername);
    }
    const storedChatHistory = sessionStorage.getItem("chatHistory");
    if (storedChatHistory && chatHistory.length === 0) {
      setChatHistory(JSON.parse(storedChatHistory));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  }, [chatHistory]);
  const handleNavigate = () => {
    navigate("/chathistory");
  }
  const handleNavigate2 = () => {
    navigate("/predictionall");
  }
  const handleLogout = () => {
    // Clear session storage
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("chatHistory");

    // Redirect the user to the login page or perform any other logout actions
    navigate("/");
  };
  const handleThumbsUpClick = () => {
    setThumbsUpValue(1);
    setIsModalOpen(true); // Open the modal when thumbs-down is clicked
    setShowSuggestions(true);
    setPositiveSuggestions(true);
  };

  const handleThumbsDownClick = () => {
    setThumbsDownValue(-1);
    setIsModalOpen(true); // Open the modal when thumbs-down is clicked
    setShowSuggestions(true);
    setPositiveSuggestions(false);
  };
  const handleThumbsDown = () => {

    handleThumbsDownClick(); // Open modal if needed
  };
  const handleThumbsUp = () => {

    handleThumbsUpClick();


  }


  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setShowSuggestions(false);
  };
  const handleSendComment = () => {
    console.log('Comment sent:', commentValue);
    setCommentValue('');
    sendFeedback(); // Send feedback data after commenting
  };
  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };
  const handleSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
    // setShowSuggestions(false); 
    // Close the suggestions modal
  };
  const sendFeedback = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/api/v1/chat/submit/${username2}/streaming/feedback`;

    try {
      let ratingValue = 0; // Initialize the rating value

      if (thumbsUpValue === 1) {
        ratingValue = 1; // Set ratingValue to 1 if thumbsUpValue is 1 (thumbs-up clicked)
      } else if (thumbsDownValue === -1) {
        ratingValue = -1; // Set ratingValue to -1 if thumbsDownValue is -1 (thumbs-down clicked)
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rating: ratingValue, // Use the determined rating value
          feedback: commentValue,
          select_comment: selectedSuggestion
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
        toast.error('Failed to send your feedback');
      }

      const data = await response.json();
      console.log('Feedback sent:', data);
      // Reset state after sending feedback
      setCopiedData('');
      setThumbsDownValue(0); // Reset thumbsDownValue
      setThumbsUpValue(0); // Reset thumbsUpValue
      setCommentValue('');
      closeModal(); // Close the modal if needed
      toast.success('Thanks for your feedback');
    } catch (error) {
      console.error('Error sending feedback:', error);
      // Handle error appropriately
    }
  };
  const handleCopy = (answer) => {
    setCopiedData(answer);
    navigator.clipboard.writeText(answer)
      .then(() => {
        console.log('Answer copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy answer: ', err);
      });
  };
  const formatResponseText = (text) => {
    return text
      .replace(
        /Source: \[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
      )
      .replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
      )
      .replace(/### (.+)/g, '<h3>$1</h3>')
      .replace(/- \*\*(.+)\*\*: (.+)/g, '<li><strong>$1</strong>: $2</li>')
      .replace(/- (.+)/g, '<li>$1</li>')
      .replace(/(\*\*)([^*]+)(\*\*)/g, '<strong>$2</strong>')
      .replace(/(\*)([^*]+)(\*)/g, '<em>$2</em>')
      .replace(/\n/g, '<br>') // Replace line breaks with <br> for proper formatting
      .replace(/<li>([^<]+)<\/li>/g, '<ul><li>$1</li></ul>') // Wrap list items in <ul>
      .replace(/<\/ul><ul>/g, ''); // Remove consecutive <ul> tags
  };

  return (
    <Div>
      {loading && (
        <div className="loader-overlay">

          <img className="imageloader" src={loaderImage} alt="Loader" />

        </div>
      )}

      <ToastContainer />
      <Div2>
        <Div3>
          <Column>
            <Div4>
              <Div5>
                <Div6>
                  <Img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a92a0e7062bdd93731a7c77fe72aec2679ae21281ad7a6ce09d5cd472881df03?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                  />
                </Div6>
                <Div7>
                  <Div8>
                    <Div9>
                      <Img2
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd9a32a6d9d8c1356ffc219b0af125b7820360ccb876e3870a60f5ff5f74c395?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div10>New Chat</Div10>
                    </Div9>
                  </Div8>
                  <Div11>
                    <Div12>
                      <Img3
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/52a59a6ef132f7f989c9ffd3394a0d583483bf347fa0032b483736dca6bf88ac?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div13 style={{ "cursor": "pointer" }} onClick={handleNavigate}>Chat History</Div13>
                    </Div12>

                  </Div11>
                  <Div11>
                    <Div12>
                      <Img3
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/52a59a6ef132f7f989c9ffd3394a0d583483bf347fa0032b483736dca6bf88ac?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div13 style={{ "cursor": "pointer" }} onClick={handleNavigate2}>Prediction All</Div13>
                    </Div12>

                  </Div11>
                </Div7>
              </Div5>
              <Div18>
                <Div19>
                  <Img4
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/607dc7965e53bcf944f399896655506dc0d2f6d95fd61c1df02bf14534244238?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                  />
                  <Div20>Settings</Div20>
                </Div19>

                <Div19 onClick={handleLogout} style={{ "cursor": "pointer" }}>




                  <img className=""
                    style={{ "height": "24px", "width": "24px" }}
                    src='assets/images/logout.png' alt="Logo" />


                  <span className="df-ms-3"
                  >
                    Logout
                  </span>


                </Div19>
                <Div21>
                  <Div22>
                    <Img5
                      loading="lazy"
                      src={profile}
                    />
                    <Div23 />
                  </Div22>
                  <Div24>
                    <Div25> {username2}</Div25>

                  </Div24>
                </Div21>
              </Div18>
            </Div4>
          </Column>
          <Column2>
            <Div27>
              <Div28>
                <Div29>
                  {!chatHistory.length && (
                    <Div30>
                      <Img6
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f0acfd34210e071875a89d086ad948a3c91a68cedb06ede59ecd2912bf7165ee?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                      />
                      <Div31>Welcome  {username2}</Div31>
                      <Div32>
                        I'm RAG, your dedicated generative AI companion, crafted
                        to enhance the Universal experience from within.
                        <br />
                        Consider me your behind-the-scenes guide, equipped to
                        illuminate the answers and support you need, helping to
                        streamline our collective efforts toward unparalleled
                        guest experiences.
                        <br />
                        Let the magic begin!
                      </Div32>
                    </Div30>
                  )}
                  <Div33>
                    {!chatHistory.length && (
                      <Div34>
                        <Div35>
                          <Div36>
                            <Div37>Creative Writing Assistance</Div37>
                            <Div38>
                              Help me write a blog post about the future of UX
                              Research in the Age of AI.
                            </Div38>
                          </Div36>
                          <Div39>
                            <Div40>Programming and Coding Help </Div40>
                            <Div41>
                              I'm struggling with a Python function. How can I
                              sort a list of dictionaries by a specific key in
                              each dictionary?
                            </Div41>
                          </Div39>
                          <Div42>
                            <Div43>Company Information </Div43>
                            <Div44>
                              Who should I contact about submitting my expense
                              report?
                            </Div44>

                          </Div42>
                        </Div35>
                      </Div34>
                    )}


                    <Div45>
                      {chatHistory.map((item, index) => (
                        <ChatContainer key={index}>
                          <ChatMessage
                            avatar="https://cdn.builder.io/api/v1/image/assets/TEMP/ff0cb8c3c76b9eb76bf2273301f838af4e222d5faf2c45f2a4b89d436c6dca09?apiKey=a938513dc279413eaac5ce0f8c637f66&"
                            name={username2}
                            timestamp={item.timestamp}
                          >
                            {item.question}
                          </ChatMessage>
                          <ChatResponse timestamp={item.timestamp}>
                            {/* {item.answer} */}
                            {formatResponseText(item.answer)}

                          </ChatResponse>
                        </ChatContainer>
                      ))}

                      <ChatInputSection>
                        <ChatInputWrapper>
                          <ChatInput type="text"

                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSubmit();
                              }
                            }} placeholder="Start typing..." aria-label="Type your message" />
                          <SendIcon
                            onClick={handleSubmit}
                            style={{ cursor: "pointer" }}
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9404523851c63b28fc972cbf52972aa0d48db7ae07ddd25564c60c652071608?apiKey=aafae26bdbcc40e0829623d48788b669&"
                            alt="Send message"
                          />
                        </ChatInputWrapper>
                      </ChatInputSection>
                      {showSuggestions && (
                        <div className="suggestions-modal">
                          <div className="suggestions">
                            <div>
                              <h6 style={{
                                width: "80%"
                              }}>Additional Feedback</h6>
                              <span className="close-modal" onClick={closeModal} style={{
                                cursor: "pointer"
                              }}>
                                &times;
                              </span>

                            </div>
                            {positiveSuggestions ? (
                              // Render positive suggestions if positiveSuggestions state is true
                              <>
                                <div className="suggestion" onClick={() => handleSelectSuggestion("Good job!")}>
                                  Good job!
                                </div>
                                <div className="suggestion" onClick={() => handleSelectSuggestion("Very helpful!")}>
                                  Very helpful!
                                </div>
                              </>
                            ) : (

                              <>
                                <div className="suggestion" onClick={() => handleSelectSuggestion("Answer Is Not Relevant")}>
                                  Answer Is Not Relevant
                                </div>
                                <div className="suggestion" onClick={() => handleSelectSuggestion("Some Content Is Missing")}>
                                  Some Content Is Missing
                                </div>
                                <div className="suggestion" onClick={() => handleSelectSuggestion("Not Meets The Required Output")}>
                                  Not Meets The Required Output
                                </div>
                              </>
                            )}



                            {isModalOpen && (
                              <div className="modal-overlay">

                                <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                                  {/* Comment input */}
                                  {/* <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter your comment"
            className="comment-input"
          /> */}
                                  <input type="text" style={{ width: "90%" }} value={selectedSuggestion}
                                    className="comment-input"
                                    readOnly
                                  />
                                  <div>
                                    <input
                                      type="text"
                                      value={commentValue}
                                      onChange={handleCommentChange}
                                      placeholder="Enter your comment"
                                      className="comment-input"
                                      style={{
                                        width: "90%"
                                      }}
                                    />

                                    <img onClick={handleSendComment} style={{ cursor: "pointer", position: "relative", height: "25px", width: "25px", "vertical-align": "middle" }} alt="Frame" src="assets/images/send.svg" />
                                  </div>

                                </div>

                              </div>
                            )}
                          </div>

                        </div>
                      )}


                    </Div45>
                  </Div33>

                </Div29>
              </Div28>
            </Div27>
          </Column2>
        </Div3>
      </Div2>
    </Div>
  );
}

const Div = styled.div`
background-color: var(--secondary-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div2 = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  gap: 20px;
  height: 100vh;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 21%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
 
  border: 0px solid var(--Gray-200, #eaecf0);
  background-color: var(--secondary-color);
  justify-content: space-between;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  
 
`;

const Div5 = styled.div`


  padding-top: 32px;
  display: flex;
  flex-direction: column;
`;

const Div6 = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0 24px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Img = styled.img`
  aspect-ratio: 2.94;
  object-fit: auto;
  object-position: center;
  width: 96px;
`;

const Div7 = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  padding: 0 16px;
`;

const Div8 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  line-height: 24px;
  justify-content: center;
`;

const Div9 = styled.div`
  border-radius: 25px;
  border-color: rgba(179, 186, 188, 1);
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 8px;
  padding: 8px 16px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin: auto 0;
`;

const Div10 = styled.div`
  font-family: Inter, sans-serif;
`;

const Div11 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

const Div12 = styled.div`
  border-radius: 6px;
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  line-height: 150%;
  padding: 12px;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div13 = styled.div`
  font-family: Inter, sans-serif;
  flex: 1;
`;

const Div14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  justify-content: center;
  padding: 0 23px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const Div15 = styled.div`
  padding-left: 16px;
  border-color: rgba(25, 101, 129, 1);
  border-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
`;

const Div16 = styled.div`
  padding-top: 8px;
  color: #91a5ac;
  font: 500 14px Inter, sans-serif;
`;

const Div17 = styled.div`
  border-radius: 4px;
  background-color: #22799a;
  margin-top: 8px;
  color: #fff;
  justify-content: center;
  padding: 4px;
  font: 600 12px Inter, sans-serif;
`;

const Div18 = styled.div`

  background-color: var(--secondary-color);

  display: flex;
  position: realative;
bottom: 0;
 
  flex-direction: column;
  padding: 16px 16px 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div19 = styled.div`
  border-radius: 6px;
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: var(--Primary-100, #f4ebff);
  font-weight: 600;
  white-space: nowrap;
  line-height: 150%;
  padding: 12px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const Div20 = styled.div`
  font-family: Inter, sans-serif;
  flex: 1;
`;

const Div21 = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 8px;
`;

const Div22 = styled.div`
  disply: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 1;
  width: 48px;
`;

const Img5 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div23 = styled.div`
  position: relative;
  border-radius: 200px;
  border-color: rgba(16, 24, 40, 1);
  border-style: solid;
  border-width: 1px;
  height: 48px;
`;

const Div24 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 143%;
  margin: auto 0;
`;

const Div25 = styled.div`
  color: var(--Base-White, var(--Base-White, #fff));
  font-family: Inter, sans-serif;
  font-weight: 600;
`;

const Div26 = styled.div`
  color: var(--Primary-200, var(--Primary-200, #e9d7fe));
  font-family: Inter, sans-serif;
  font-weight: 400;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 79%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div27 = styled.div`
 
  justify-content: flex-end;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div28 = styled.div`
height: 90vh;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px 0px 0px 0px;
  background-color: #f6faff;
  display: flex;
  flex-direction: column;
  padding: 50px 10px 3px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div29 = styled.div`
  display: flex;

  max-width: 100%;
  flex-direction: column;
      height: 89vh;
  
    overflow: auto;
    scrollbar-width: thin;
    justify-content: space-between;
`;

const Div30 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 108px;
  max-width: 100%;
`;

const Div31 = styled.div`
  color: var(--Gray-900, var(--Gray-900, #101828));
  margin-top: 18px;
  font: 600 30px/127% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  align-self: stretch;
  color: var(--Gray-600, var(--Gray-600, #475467));
  margin-top: 18px;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div33 = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div34 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div35 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div36 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div37 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div38 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div39 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div40 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div41 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div42 = styled.div`
  border-radius: 16px;
  border-color: rgba(227, 227, 227, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fcfcfc;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div43 = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div44 = styled.div`
  color: #54617b;
  font-family: Inter, sans-serif;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div45 = styled.div`
  border-radius: 36px;
  box-shadow: -4px 3px 11px 0px rgba(3, 46, 63, 0.07);
  border-color: rgba(205, 223, 230, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  margin-top: 29px;
  flex-direction: column;
  font-size: 16px;
  color: #8d8d8d;
  font-weight: 400;
  line-height: 150%;
  justify-content: center;
  padding: 10px 10px 10px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div46 = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div47 = styled.div`
  font-family: Inter, sans-serif;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 44px;
  fill: linear-gradient(97deg, #09455c 13.13%, #1385b0 149.17%);
`;

const Div48 = styled.div`
  color: var(--Grey-Grey-6, #000);
  text-align: center;
  -webkit-text-stroke-width: 2;
  -webkit-text-stroke-color: var(--Gray-gray-1, #fff);
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  align-self: start;
  margin: 14px 0 0 68px;
  font: 450 10px/110% Neue Haas Grotesk Display Std, -apple-system, Roboto,
    Helvetica, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;
const ChatInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
`;
const ChatInputWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
`;
const ChatInputSection = styled.div`
  background-color: #fff;

  border-radius: 36px;
}
`;
const SendIcon = styled.img`
  width: 30px;
  cursor: pointer;
`;
const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 664px;
`;

const ChatMessageWrapper = styled.div`
  background-color: #ebf3fd;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  padding: 16px 40px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16px 20px;
  }
`;

const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ChatMessageAvatar = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const ChatMessageName = styled.div`
  color: #101828;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatMessageTimestamp = styled.div`
  color: #575b63;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatMessageContent = styled.div`
  color: #101828;
  font: 500 16px/24px Inter, sans-serif;
`;

const ChatResponseWrapper = styled.div`
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 40px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 16px 20px;
  }
`;

const ChatResponseHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ChatResponseAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    object-position: center;
  }
`;

const ChatResponseName = styled.div`
  color: #101828;
  font-family: Inter, sans-serif;
`;

const ChatResponseTimestamp = styled.div`
  color: #575b63;
  font-family: Inter, sans-serif;
`;

const ChatResponseContent = styled.div`
  color: #101828;
  font: 400 14px/24px Inter, sans-serif;
`;

const ChatResponseIndicator = styled.div`
  width: 34px;
  height: 6px;
  margin-top: 24px;
`;


